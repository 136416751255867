import React, { PropsWithChildren, useEffect } from "react";

import backgroundImage from "../assets/images/web-background-2.png";

import AOS from "aos";
import "aos/dist/aos.css";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      {/* <Header /> */}
      <div
        // className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat"
        className="absolute inset-0 z-0 bg-cover bg-center bg-fixed"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <main className="grow">{children}</main>
    </>
  );
};

export default Layout;
