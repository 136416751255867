import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.css';
import Layout from './layouts';
import Home from './pages/home';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Layout>
      <Home />
    </Layout>
  </React.StrictMode>
);


