import LogoImage from "../assets/images/Logo.png";
import AppStoreDownload from "../assets/images/appstore_download.png";
import GooglePlayDownload from "../assets/images/googleplay_download.png";
import AppCover from "../assets/images/app-thumbnail.png";

const Home = () => {
  return (
    <section className="relative min-h-screen">
      <div className="flex flex-col md:flex-row mx-auto px-8 md:px-16 pt-8 md:pt-16">
        {/* Left column for logo, slogan, and App Store download */}
        <div className="flex flex-col md:w-1/2 mb-12 md:mb-0">
          <div>
            {/* Logo header */}
            <div className="pb-12 flex items-center gap-x-4">
              <img
                className="box-content"
                src={LogoImage}
                width={220}
                height={61}
                alt="Company Logo"
              />
            </div>

            {/* Slogan */}
            <div className="text-left font-bold text-xl md:text-2xl leading-normal md:leading-relaxed tracking-wide md:tracking-wider mb-10">
              <h1
                className="bg-gradient-to-r bg-clip-text text-transparent from-white/30 to-70% to-white text-4xg md:text-5xl"
                data-aos="zoom-y-out"
                data-aos-delay={150}
              >
                AI-assisted Search
              </h1>
              <h1
                className="bg-gradient-to-r bg-clip-text text-transparent from-white/50 to-60% to-white text-4xg md:text-5xl"
                data-aos="zoom-y-out"
                data-aos-delay={150}
              >
                Illuminate your exploration
              </h1>
            </div>

            {/* App Store Download */}
            <div className="mt-10 flex gap-10">
              <a href="https://apps.apple.com/app/id6639597533">
                <img
                  src={AppStoreDownload}
                  width={162}
                  height={48}
                  alt="Download from App Store"
                  data-aos="zoom-y-out"
                  data-aos-delay={150}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.dotline.search">
                <img
                  src={GooglePlayDownload}
                  width={162}
                  height={48}
                  alt="Download from Google Play"
                  data-aos="zoom-y-out"
                  data-aos-delay={150}
                />
              </a>
            </div>
          </div>
        </div>

        {/* Right column for App thumbnail */}
        <div className="md:w-1/2 flex justify-center items-center">
          <img
            className="box-content w-[80%] motion-safe:animate-wiggle"
            src={AppCover}
            width={335}
            height={699}
            alt="App Thumbnail"
            data-aos="zoom-y-out"
            data-aos-delay={150}
          />
        </div>
      </div>

      {/* App Store Download for mobile */}
      <div className="hidden mt-12 px-8 text-center">
        <a href="https://apps.apple.com/app/id6639597533">
          <img
            src={AppStoreDownload}
            width={160}
            height={48}
            alt="Download from App Store"
            data-aos="zoom-y-out"
            data-aos-delay={150}
          />
        </a>
      </div>
    </section>
  );
};

export default Home;
